<template>
    <div 
        class="conteiner-tabs" 
        id="soyUnTab"
        ref='TAB' 
        v-if="type === 'sessions'"
    >
        <div 
            v-for="(session,index) in arraySessionsFunction(arraySessions)"
            class="butonTabs" 
            :key="index"
            :id="index"
            @click="selectCheckbox(session, index)"
        >
            {{session}}     
        </div>
        <div style="width:5%; height: 10px"></div>
    </div>
    <div 
        class="container-tabs-cards"
        id="soyUnTab"
        ref='TAB'
        v-if="type === 'sessionsCards'"
    >
        <div 
            class="contenedor-check"
            :style="(index !== 0) ? 'margin-left:10px' : 'margin-left:0px;'"
            v-for="(session,index) in arraySessionsFunction(arraySessions)"
            :key="index"
            :id="index"
        >
            <p class="p-check">{{session}}</p>
            <div 
                class="checkbox" 
                @click="selectCheckbox(session, index)"
            >
                <div v-if="arrayTabs[session] === true" class="caja"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TabsButton",
        props: {
            arraySessions: {
                type: Array,
                default: [],
            },
            type: {
                type: String,
                default: 'sessions'
            },
            firstSelect: {
                type: Boolean,
                default: false
            },
            bulReset: {
                type: Boolean,
                default: false
            },
            vModel: {
                type: Function,
                default: () => {}
            },
            vResetBul: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return{
                numberBefore: 0,
                arrayTabs: {},
                selectTab: ['',0],
                bulIteraded: false
            }
        },
        methods: {

            //put the new data

            arraySessionsFunction(array){

                if(!this.bulIteraded){
                    let arrayTabsMemory = new Object()
                    this.bulIteraded = true

                    array.forEach((data, index) => {
                        if(index === 0 && this.firstSelect){
                            arrayTabsMemory[data] = true 
                            this.selectTab = [data,index]
                        }else{
                            arrayTabsMemory[data] = false
                        } 
                    })

                    this.arrayTabs = arrayTabsMemory
                }            

                return array
            },

            //funcion encargada de seleccionar el checkbox

            selectCheckbox(session, index){
                if(this.selectTab[0] !== ''){
                    this.arrayTabs[this.selectTab[0]] = false
                }
                this.arrayTabs[session] = true

                this.selectTab = [session,index]
            },

            //reset select

            resetCheckbox(){
                this.arrayTabs[this.selectTab[0]] = false
            },
        },
        watch: {
            arrayTabs(newValue){
                this.vModel(newValue)
            },
            bulReset(newValue){
                if(newValue === true){
                   this.resetCheckbox() 
                   this.vResetBul()
                }
            }
        },
    }
</script>

<style scoped>
    .conteiner-tabs{
        width: 100%; 
        margin-top:0px; 
        height: 40px;
        display: flex;
        justify-content: right;
    }
    .butonTabs{
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        background:#ffffffd6;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25);
        border-radius: 15px 0px 15px 0px;
        cursor: pointer;
        width: auto;
        transition: 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 15px;
        margin-left: 20px;
    }
    @media (max-width: 578px) {
        .conteiner-tabs{
            height: 40px;
        }
        .butonTabs{
            width:70px;
            font-size: 16px;
            border-radius:0px 10px 0px 10px;
            box-shadow: inset 0 0 15px white;
        }
    }
    .container-tabs-cards{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
    }
    .contenedor-check{
        background:rgba(196,196,196,0.25);
        width:auto;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        cursor:pointer;
        transition: all 1s;
        border-radius: 10px;
        display: flex;
        flex: row;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
    }
    .p-check{
        margin: 0px;
    }
    .checkbox{
        border: #6464646b 2px solid;
        background: white;
        margin-right: 0px;
        border-radius:5px;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-top:0px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        padding: 0px;
    }
    .caja{
        position: absolute;
        width: 13px;
        height: 13px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        z-index: 10;
        border-radius:2.5px;
        transform: scale(1);
        margin-left: -1px;
        margin-top: -1px;
        padding: 0px;
        animation: animateCaja 0.4s ease-in-out;
    }
    @keyframes animateCaja{
        0%{
            opacity: 0;
            transform: scale(0.2);
        }
        100%{
            opacity: 1;
            transform: scale(1);
        }
    }
</style>