<template>
    <div class="container-all" v-if="boolVisible" ref="CONTAINERALL">
        <div 
            class="continer-space"
            :style="bulWindowSearch ? `height:calc(${clientHeightData()}px + 50px);  margin-top:-50px; top:10px; padding-top: 50px;` :
            `height:0px;  margin-top:10px; top:0px; padding-top: 0px;`"
        >
            <div 
                :class="bulWindowSearch ? 'container-input-search-open' : 'container-input-search'" 
                style="display:none;" 
                ref="SEARCH"
            >
                <div 
                    class="space" 
                    v-for="({name, type, reference, number, numberFunction}, index) in dataInputTransformer(arrayNameColumns)" 
                    :key="index"
                > 
                    <InputsCustom 
                        v-if="type === 'text'"
                        :type="type"
                        :label="name"
                        :vModel="(response) => dataValues[0][`${reference}${numberFunction}`][0] = response"
                        :vModelFather="dataValues[0][`${reference}${numberFunction}`][0]"
                        className="input2" 
                        @keyup="filterTable()"
                    />
                    <InputsCustom 
                        v-if="type === 'date'"
                        :type="type"
                        :label="`${name} ${dataValues[1][`${reference}${number}${numberFunction}`][2]}`"
                        :vModel="(response) => dataValues[1][`${reference}${number}${numberFunction}`][0] = response"
                        :vModelFather="dataValues[1][`${reference}${number}${numberFunction}`][0]"
                        className="input2" 
                        @keyup="filterTable()"
                        @change="filterTable()"
                    />
                    <InputsCustom 
                        v-if="type === 'number'"
                        :type="type"
                        :label="`${name} ${dataValues[2][`${reference}${number}${numberFunction}`][2]}`"
                        :vModel="(response) => dataValues[2][`${reference}${number}${numberFunction}`][0] = response"
                        :vModelFather="dataValues[2][`${reference}${number}${numberFunction}`][0]"
                        className="input2" 
                        @keyup="filterTable()"
                        @change="filterTable()"
                    />
                </div>
                <div class="container-reset">
                    <button class="button" @click="resetData()">Reset</button>
                </div>
            </div>
        </div>
        <div class="container-table">
            <div 
                class="container-tables d-flex flex-column" 
                :style="`height:${heightTable}px;`" 
                ref="TAB"
            >      
                <div class="container-header d-flex flex-row align-items-center">
                    <div class="container-table-header">
                        <div 
                            class="box-table-header" 
                            :style="`width:${width}px; flex-shrink: 0;`"
                            v-for="({name , width}, index) in arrayNameColumns" :key="index"
                        >
                            <p class="nameColumn">{{name}}</p>
                        </div>
                        <div class="box-table-header corner-table"></div>
                    </div>
                    <div class="container-sticky" style="z-index:13">
                        <div class="shadow-actions"></div>
                        <div class="container-icons">
                            <div 
                                class="search-icon" 
                                @click="openFilter()"
                            >
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!dataSourceFilter.length" class="container-table-no-data" >
                    <div class="table-no-data" :style="`height:calc(${heightTable}px - 70px);`">
                        <img 
                            src="../assets/pictures/carpeta-icon.svg" 
                            alt="icono carpeta"
                            class="carpeta-icon"
                        >
                        <h2 class="text-table-no-data" :style="`font-size:${heightTable < 400 ? 12 : 30}px;`">
                            Todavia no tienes movimientos registrados
                        </h2>
                    </div>
                </div>
                <div 
                    class="container-source d-flex flex-row" 
                    v-for="(data, index) in dataSourceFilter" 
                    v-bind:key="index" 
                >
                    <div class="container-table-source-class" id="container-table-source">
                        <div 
                            class="box-table-source" 
                            :style="`width:${width}px; flex-shrink: 0; position:relative;`" 
                            v-for="({width, reference, functionData}, index) in arrayNameColumns" 
                            :key="index"
                        >   
                            <TextComponent
                                :text="
                                    `${(!functionData) ? 
                                    data[`${reference}`] : 
                                    functionData(data[`${reference}`])}`
                                "
                                lineHeightProp="35px"
                            />
                        </div>
                        <div class="box-table-source end-box"></div>
                    </div>
                    <div class="container-sticky">
                        <div class="shadow-actions"></div>
                        <div class="d-flex justify-content-center align-items-center box-edit-action" @click="actionEdit(data)">
                            <div class="icon-edit"></div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center box-delete-action" @click="actionDelete(data)">
                            <div class="icon-delete"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenedor-modal" v-if="bulModal">
            <div class="card-modal">
                <p class="text-modal">{{textSelect}}</p>
            </div>
        </div>
    </div>
    
</template>

<script>
    import {mapActions} from "vuex"
    import TextComponent from "./TextComponent.vue"
    import InputsCustom from './InputsCustom.vue'

    export default {
        name: "Table",
        components: {
            TextComponent,
            InputsCustom
        },
        props: {
            arrayNameColumns: {
                type: Array,
                default: [],
            },
            dataSourceFilter: {
                type: Array,
                default: [],
            },
            actionEdit: {
                type: Function,
            },
            actionDelete: {
                type: Function,
            },
            stateData: {
                type: Object,
                default: {}
            },
            heightTable: {
                type: Number,
                default: 400
            },
            boolVisible: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                bulWindowSearch: false,
                bulIteradeReady: false,
                bulNotMore: false,
                bulModal: false,
                arrayMemoryInputs: [],
                dataValues: {},
                reset: {},
                clock1: '',
                height: -10,
                textSelect: '',
            }
        },
        methods: {

            //mapActions VUEX
            ...mapActions([
                "tableSearchAction",
                "swiftMessageTextComplete"
            ]),

            //funcion encargada de devolver la altura del contenedor del filtro
            searchClientHeightData() {
                if(this.$refs.SEARCH !== undefined && this.$refs.SEARCH !== null){
                   return this.$refs.SEARCH.clientHeight 
                }
            },

            //funcion encargada de devolver la altura total de tabla
            clientHeightData() {
                if(this.$refs.CONTAINERALL !== undefined && this.$refs.CONTAINERALL !== null){
                   return this.$refs.CONTAINERALL.clientHeight 
                }
            },

            //esta funcion toma el arreglo de los inputs cuando los mismo cambian, y filtra el state del dataSourceFilter pasado por props
            filterTable() {
                this.tableSearchAction({
                    value:this.dataValues, 
                    data:this.stateData,
                })
            },

            //esta funcion toma el array name columns y devuelve un arreglo segun los parametros de cada columna para construr los inputs de la tabla
            dataInputTransformer(array) {
                if(this.arrayMemoryInputs.length === 0){
                    const newArray = []
                    let arrayDataValuesText = new Object()
                    let arrayDataValuesDate = new Object()
                    let arrayDataValuesNumber = new Object()
                    let numDate = 0
                    let numNumber = 0
                    let numFunction = 0

                    array.forEach((data) => {
                        if(data.type === "date"){
                            if(!data.functionData){
                                newArray.push({...data, number: numDate + 1, numberFunction: ''})
                                newArray.push({...data, number: numDate + 2, numberFunction: ''})

                                arrayDataValuesDate[`${data.reference}${numDate + 1}`] = ['',data.type,'desde']
                                arrayDataValuesDate[`${data.reference}${numDate + 2}`] = ['',data.type,'hasta'] 
                            }else{
                                newArray.push({...data, number: numDate + 1, numberFunction: numFunction + 1})
                                newArray.push({...data, number: numDate + 2, numberFunction: numFunction + 2})

                                arrayDataValuesDate[`${data.reference}${numDate + 1}${numFunction + 1}`] = ['',data.type,'desde',data.functionData]
                                arrayDataValuesDate[`${data.reference}${numDate + 2}${numFunction + 2}`] = ['',data.type,'hasta',data.functionData] 
                                
                                numFunction = numFunction + 2
                            }
                            
                            numDate = numDate + 2

                            return
                        }
                        if(data.type === "number"){
                            if(!data.functionData){
                                newArray.push({...data, number: numNumber + 1, numberFunction: ''})
                                newArray.push({...data, number: numNumber + 2, numberFunction: ''})

                                arrayDataValuesNumber[`${data.reference}${numNumber + 1}`] = ['',data.type,'maximo',data.functionData]
                                arrayDataValuesNumber[`${data.reference}${numNumber + 2}`] = ['',data.type,'minimo',data.functionData]
                            }else{
                                newArray.push({...data, number: numNumber + 1, numberFunction: numFunction + 1})
                                newArray.push({...data, number: numNumber + 2, numberFunction: numFunction + 2})

                                arrayDataValuesNumber[`${data.reference}${numNumber + 1}${numFunction + 1}`] = ['',data.type,'maximo',data.functionData]
                                arrayDataValuesNumber[`${data.reference}${numNumber + 2}${numFunction + 2}`] = ['',data.type,'minimo',data.functionData]

                                numFunction = numFunction + 2
                            }
                            
                            numNumber = numNumber + 2

                            return
                        }

                        if(!data.functionData){
                            newArray.push({...data, numberFunction: ''})
                            arrayDataValuesText[data.reference] = ['',data.type,data.functionData]    
                        }else{
                            newArray.push({...data, numberFunction: numFunction + 1})
                            arrayDataValuesText[`${data.reference}${numFunction + 1}`] = ['',data.type,data.functionData]
                            numFunction++
                        }
                    })

                    this.dataValues = [arrayDataValuesText,arrayDataValuesDate,arrayDataValuesNumber]
                    this.arrayMemoryInputs = newArray

                    return newArray
                }else{
                    return this.arrayMemoryInputs
                }
            },

            //funcion encargada del despligue de los filtros
            openFilter(){
                if(!this.bulWindowSearch){
                    this.$refs.SEARCH.style.cssText = 'display:flex;'
                    this.clock1 = setTimeout(() => {
                        this.bulWindowSearch = true;  
                    }, 50);
                }else{
                    this.bulWindowSearch = false; 
                    this.clock1 = setTimeout(() => {
                        this.$refs.SEARCH.style.cssText = 'display:none;'  
                    }, 750);
                }
            },

            //esta funcion vuelve a su esta inicial el stateDataModel creado para los inputs
            resetData(){
                let arrayDataValuesText = new Object()
                let arrayDataValuesDate = new Object()
                let arrayDataValuesNumber = new Object()
                let numDate = 0
                let numNumber = 0
                let numFunction = 0

                this.arrayNameColumns.forEach((data) => {
                    if(data.type === "date"){
                        if(!data.functionData){

                            arrayDataValuesDate[`${data.reference}${numDate + 1}`] = ['',data.type,'desde']
                            arrayDataValuesDate[`${data.reference}${numDate + 2}`] = ['',data.type,'hasta'] 

                        }else{

                            arrayDataValuesDate[`${data.reference}${numDate + 1}${numFunction + 1}`] = ['',data.type,'desde',data.functionData]
                            arrayDataValuesDate[`${data.reference}${numDate + 2}${numFunction + 2}`] = ['',data.type,'hasta',data.functionData] 
                            
                            numFunction = numFunction + 2
                        }
                        
                        numDate = numDate + 2

                        return
                    }
                    if(data.type === "number"){
                        if(!data.functionData){

                            arrayDataValuesNumber[`${data.reference}${numNumber + 1}`] = ['',data.type,'maximo',data.functionData]
                            arrayDataValuesNumber[`${data.reference}${numNumber + 2}`] = ['',data.type,'minimo',data.functionData]
                        
                        }else{

                            arrayDataValuesNumber[`${data.reference}${numNumber + 1}${numFunction + 1}`] = ['',data.type,'maximo',data.functionData]
                            arrayDataValuesNumber[`${data.reference}${numNumber + 2}${numFunction + 2}`] = ['',data.type,'minimo',data.functionData]

                            numFunction = numFunction + 2
                        }
                        
                        numNumber = numNumber + 2

                        return
                    }

                    if(!data.functionData){
                        arrayDataValuesText[data.reference] = ['',data.type,data.functionData]    
                    }else{
                        arrayDataValuesText[`${data.reference}${numFunction + 1}`] = ['',data.type,data.functionData]
                        numFunction++
                    }
                })
                
                this.dataValues = [arrayDataValuesText,arrayDataValuesDate,arrayDataValuesNumber]
                this.filterTable()
            }
        }, 
        watch: {
            boolVisible(newValue){
                this.bulWindowSearch = false; 
                if(newValue && this.$refs.SEARCH !== undefined && this.$refs.SEARCH !== null){
                    this.$refs.SEARCH.style.cssText = 'display:none;'  
                }
            },
        }
    }
</script>

<style scoped>
    .contenedor-modal{
        top: 0px;
        left: 0px;
        position: absolute;
        width: 100%;
        height: calc(100% + 645px);
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-modal{
        background: rgba(255, 255, 255, 0.75);
        width: 50%;
        height: auto;
        transition: all 1s;
        position: relative;
    }
    .text-modal{
        word-break: break-all;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
    }
    .container-all{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .continer-space{
        position: relative;
        width: 100%;
        transition: all 0.75s ease-in-out;
        overflow: hidden;
    }
    .container-table{
        position: relative;
        width: 100%;
        height: auto;
    }
    .container-input-search{
        width: 100%;
        height: auto;
        position: absolute;
        left: 0px;
        margin-top:15px;
        background:rgba(196,196,196,0.25);
        border-radius:10px 10px 0px 0px;
        padding: 20px;
        padding-bottom: 45px;
        transform: translateY(0%) scaleY(1);
        perspective: 600;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        opacity: 0;
        transition: all 0.75s ease;
    }
    .container-input-search-open{
        width: 100%;
        height: calc(100% - 50px);
        transform: translateY(0%) scaleY(1);
        position: absolute;
        perspective: 600;
        left: 0px;
        margin-top:5px;
        background:rgba(196,196,196,0.25);
        border-radius:10px 10px 0px 0px;
        padding: 20px;
        padding-bottom: 45px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        opacity: 1;
        transition: all 0.75s ease;
    }
    .container-reset{
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        top: -55px;
        left: 0px;
    }
    .button{
        height: 35px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:14px ;
        width: auto;
        font-weight: bold;
        padding: 2px 15px;
        margin-left: 0%;
        margin-top: 10px;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button:hover{
        background: transparent;
        border: 2px solid #297F87;
        transition: all 0.5s ease-in-out;
        color:#297F87 ;
    }
    .space{
        width: 250px;
    }
    .label{
        font-family: Rubik;
        font-weight:bold ;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 35px;
        margin-right: 10px;
        margin-bottom:10px;
        font-size:16px ;
        background: transparent;
    }
    .container-inputs{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    .container-tables{
        position:relative;
        width: 100%;
        background:rgba(196,196,196,0.25);
        border-radius:10px;
        transition: all 1s;
        overflow:scroll scroll;
        direction: ltr;
        padding:0px;
    }
    .container-tables::-webkit-scrollbar{
        height: 14px;
    }
    .container-tables::-webkit-scrollbar-corner{
        background: transparent;
        width:0px;
    }
    .box-table-header,.box-table-source{
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        overflow: hidden auto;
        text-overflow: ellipsis; 
        height:50px; 
        padding-top:12px;
        font-weight:bold;
        position: relative;
        z-index: 10;
    }
    .p-text-source{
        width: auto;
        max-width: calc(100% + 20px);
        display: inline-block;
        height: 100%;
        margin: 0px;
        padding: 0px;
        line-height: 35px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis; 
        transition: all 0.75s ease-in-out;
    }
    .box-table-header{
        background:rgb(196,214,216);
    }
    .end-box{
        width: 100px;
        flex-grow: 1;
    }
    .nameColumn{
        width: 100%;
        height: 100%;
        margin: 0px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0px 10px;
    }
    .container-icons{
        position: absolute;
        width: 100px;
        height: 50px;
        top: 0px;
        right: 0px;
        transition: all ease-in-out 0.5s;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        background:rgb(196,214,216);
    }
    .search-icon{
        width: 25px;
        height: 100%;
        background-image: url(../assets/pictures/search-solid.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px 15px;
        cursor: pointer;
    }
    .box-table-source{
        height:35px;
        padding-top:0px;
        font-weight:normal;
        border-bottom: #6464646b 0.5px solid;
        padding-left: 1em;
        padding-right: 1em;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        transition: all 0.5s ease-in-out;
        margin-left:0%;
        position: relative;
        z-index: 9;
    }
    .container-hover{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .corner-table{
        background:rgb(196,214,216);
        width: 100px;
        flex-grow: 1;
    }
    .container-header{
        width:100%;
        border-radius:10px 0px 0px 10px;
        position: sticky;
        top: 0px;
        z-index: 11;
    }
    .container-source{
        width:100%;
        height: 35px;
        transition: all 0.4s;
        position: relative;
    }
    .container-table-source-class, .container-table-header{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    #container-table-source:hover > .box-table-source {
        background: rgba(89, 191, 203, 0.3);
    }
    .container-actions{
        position: absolute;
        height: 100%;
        left: 0px;
        top: 0px;
        width: 100%;
    }
    .container-sticky{
        width: 0px;
        height: 100%;
        right: 0px;
        position: -webkit-sticky;
        position: sticky;
        z-index: 10;
    }
    .box-edit-action{
        position: absolute;
        width:50px;
        right:50px;
        height: 35px;
        background:rgba(217, 217, 218);
        padding-left:12px;
        backdrop-filter: blur(50px);
        border-bottom: #6464646b 0.5px solid;
    }
    .box-delete-action{
        position: absolute;
        width:50px;
        height: 35px;
        right:0px;
        background:rgba(217, 217, 218);
        padding-right:12px;
        backdrop-filter: blur(50px);
        border-bottom: #6464646b 0.5px solid;
    }
    .shadow-actions{
        position: absolute;
        -webkit-box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 12%);
        box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 12%);
        width: 35px;
        height: 100%;
        right:100px;
        content: open-quote;
    }
    .icon-edit{
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/lapiz.svg);
        transition: all 0.5s;
        cursor: pointer;
    }
    .icon-delete{
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/papelera.svg);
        transition: all 0.5s;
        cursor: pointer;
    }
    .container-table-no-data{
        position: relative;
        width: 100%;
    }
    .table-no-data{
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        padding: 3px;
        position: fixed;
        width: 100%;
    }
    .carpeta-icon{
        height: 45%;
        width: 100%;
    }
    .text-table-no-data{
        text-align: center;
    }
</style>