<template>
    <teleport to="body">
        <div 
            :class="classData" 
            :style="styleData"
        >
            <div class="card" :style="`width: ${widthCard}`">
                <div class="x-modal" @click="exitModal()"></div>
                <div class="title-form-modal">{{title}}</div>
                <slot class="container-modal-section"></slot>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        name: "ModalComponent",
        props: {
            title: {
                type: String,
                default: ''
            },
            bulModal: {
                type: Boolean,
                default: false
            },
            exitModal: {
                type: Function,
                default: () => {}
            },
            widthCard: {
                type: String,
                default: "90%"
            },
            topModal: {
                type: String,
                default: "80px"
            },
        },
        data() {
            return{
                classData: `container-modal-form-hidden`,
                styleData: `display: none;`,
                clock: 0,
                clock2: 0,
            }
        },
        watch: {
            bulModal(newValue) {
                if(newValue){
                    this.styleData = `display: flex; padding-top:${this.topModal};`
                    clearTimeout(this.clock2)
                    this.clock = setTimeout(() => {
                        this.classData = `container-modal-form`
                    }, 100);
                }
                if(!newValue){
                    clearTimeout(this.clock)
                    this.clock2 = setTimeout(() => {
                        this.styleData = `display: none;`
                    }, 400);
                    this.classData = `container-modal-form-hidden`
                }
            },
        }
    }
</script>

<style scoped>
    .container-modal-form, .container-modal-form-hidden{
        width: 100%;
        height: 100vh;
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 100;
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
        display: flex;
        align-content: center;
        flex-direction: column;
        padding: 60px 30px;
        padding-top: 120px;
        transition: all 0.4s ease-in-out;
        backdrop-filter:blur(4px);
        opacity: 0;
        overflow: hidden scroll;
    }
    .container-modal-form{
        opacity: 1;
        transition: all 0.4s ease-in-out;
    }
    .x-modal{
        position: absolute;
        top:32.5px;
        width: 15.5px;
        height: 15.5px;
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/x-negro.svg);
        transition: all 0.5s;
        right: 15px;
        cursor: pointer;
    }
    .title-form-modal{
        position: absolute;
        top: 20px;
        left: 30px;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        width: 85%;
    }
    .card{
        position:relative;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 100%, rgba(255, 255, 255, 0.85) 100%);
        border-radius: 16px;
        padding: 60px;
        padding-top: 80px;
        padding-bottom: 50px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:auto;
        margin-right: auto;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .container-modal-section{
        width: 100%;
        height: auto;
    }
</style>