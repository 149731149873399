<template>
    <div 
        class="container-input-search-filter" 
        v-if="boolVisible"
    >
        <div 
            class="container-inputs" 
            v-for="({name, type, reference, number, numberFunction}, index) in dataInputTransformer(arrayNameColumns)" 
            :key="index"
        >
            <InputsCustom 
                v-if="type === 'text'"
                :type="type"
                :label="name"
                :vModel="(response) => {
                    dataValues[0][`${reference}${numberFunction}`][0] = response;
                    arrayInputs[index] = response;
                    this.vModel(dataValues)
                }"
                :vModelFather="dataValues[0][`${reference}${numberFunction}`][0]"
            />
            <InputsCustom 
                v-if="type === 'date'"
                :type="type"
                :label="`${name} ${dataValues[1][`${reference}${number}${numberFunction}`][2]}`"
                :vModel="(response) => {
                    dataValues[1][`${reference}${number}${numberFunction}`][0] = response;
                    arrayInputs[index] = response
                    this.vModel(dataValues)
                }"
                :vModelFather="dataValues[1][`${reference}${number}${numberFunction}`][0]"
            />
            <InputsCustom 
                v-if="type === 'number'"
                :type="type"
                :label="`${name} ${dataValues[2][`${reference}${number}${numberFunction}`][2]}`"
                :vModel="(response) => {
                    dataValues[2][`${reference}${number}${numberFunction}`][0] = response;
                    arrayInputs[index] = response
                    this.vModel(dataValues)
                }"
                :vModelFather="dataValues[2][`${reference}${number}${numberFunction}`][0]"
            />
        </div>
        <div class="container-reset">
            <button class="button" @click="resetData()">Reset</button>
        </div>
    </div>
    <div class="contenedor-filtros-seleccionados" v-if="boolVisible">
        <div class="cuadro-filtro-seleccionado" v-for="(seleccion, index) in filterOptions(arrayInputs)" :key="index"><TextComponent lineHeightProp="35px" :text="seleccion" /></div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
    import InputsCustom from './InputsCustom.vue'
    import TextComponent from "./TextComponent.vue"

    export default {
        // Footer de todas las vistas
        name: "FilterExport",
        components: {
            InputsCustom,
            TextComponent
        },
        props: {
            dataSourceFilter: {
                type: Array,
                default: [],
            },
            arrayNameColumns: {
                type: Array,
                default: [],
            },
            boolVisible: {
                type: Boolean,
                default: true
            },
            vModel: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                arrayMemoryInputs: [],
                dataValues: {},
                arrayInputs: []
            }
        },
        methods: {
            //mapActions VUEX
            ...mapActions([
                "tableSearchAction",
            ]),
            dataInputTransformer(array) {
                if(this.arrayMemoryInputs.length === 0){
                    const newArray = []
                    let arrayDataValuesText = new Object()
                    let arrayDataValuesDate = new Object()
                    let arrayDataValuesNumber = new Object()
                    let numDate = 0
                    let numNumber = 0
                    let numFunction = 0

                    array.forEach((data) => {
                        if(data.type === "date"){
                            if(!data.functionData){
                                newArray.push({...data, number: numDate + 1, numberFunction: ''})
                                newArray.push({...data, number: numDate + 2, numberFunction: ''})

                                arrayDataValuesDate[`${data.reference}${numDate + 1}`] = ['',data.type,'desde']
                                arrayDataValuesDate[`${data.reference}${numDate + 2}`] = ['',data.type,'hasta'] 
                            }else{
                                newArray.push({...data, number: numDate + 1, numberFunction: numFunction + 1})
                                newArray.push({...data, number: numDate + 2, numberFunction: numFunction + 2})

                                arrayDataValuesDate[`${data.reference}${numDate + 1}${numFunction + 1}`] = ['',data.type,'desde',data.functionData]
                                arrayDataValuesDate[`${data.reference}${numDate + 2}${numFunction + 2}`] = ['',data.type,'hasta',data.functionData] 
                                
                                numFunction = numFunction + 2
                            }
                            
                            numDate = numDate + 2

                            return
                        }
                        if(data.type === "number"){
                            if(!data.functionData){
                                newArray.push({...data, number: numNumber + 1, numberFunction: ''})
                                newArray.push({...data, number: numNumber + 2, numberFunction: ''})

                                arrayDataValuesNumber[`${data.reference}${numNumber + 1}`] = ['',data.type,'maximo',data.functionData]
                                arrayDataValuesNumber[`${data.reference}${numNumber + 2}`] = ['',data.type,'minimo',data.functionData]
                            }else{
                                newArray.push({...data, number: numNumber + 1, numberFunction: numFunction + 1})
                                newArray.push({...data, number: numNumber + 2, numberFunction: numFunction + 2})

                                arrayDataValuesNumber[`${data.reference}${numNumber + 1}${numFunction + 1}`] = ['',data.type,'maximo',data.functionData]
                                arrayDataValuesNumber[`${data.reference}${numNumber + 2}${numFunction + 2}`] = ['',data.type,'minimo',data.functionData]

                                numFunction = numFunction + 2
                            }
                            
                            numNumber = numNumber + 2

                            return
                        }

                        if(!data.functionData){
                            newArray.push({...data, numberFunction: ''})
                            arrayDataValuesText[data.reference] = ['',data.type,data.functionData]    
                        }else{
                            newArray.push({...data, numberFunction: numFunction + 1})
                            arrayDataValuesText[`${data.reference}${numFunction + 1}`] = ['',data.type,data.functionData]
                            numFunction++
                        }
                    })

                    this.dataValues = [arrayDataValuesText,arrayDataValuesDate,arrayDataValuesNumber]
                    this.arrayMemoryInputs = newArray

                    return newArray
                }else{
                    return this.arrayMemoryInputs
                }
            },
            filterOptions(array){
                let newArray = []

                array.forEach((data) => {
                    if(data !== ''){
                        newArray.push(data)
                    }
                })
                
                return newArray
            },
            //esta funcion vuelve a su esta inicial el stateDataModel creado para los inputs
            resetData(){
                let arrayDataValuesText = new Object()
                let arrayDataValuesDate = new Object()
                let arrayDataValuesNumber = new Object()
                let numDate = 0
                let numNumber = 0
                let numFunction = 0

                this.arrayNameColumns.forEach((data) => {
                    if(data.type === "date"){
                        if(!data.functionData){

                            arrayDataValuesDate[`${data.reference}${numDate + 1}`] = ['',data.type,'desde']
                            arrayDataValuesDate[`${data.reference}${numDate + 2}`] = ['',data.type,'hasta'] 

                        }else{

                            arrayDataValuesDate[`${data.reference}${numDate + 1}${numFunction + 1}`] = ['',data.type,'desde',data.functionData]
                            arrayDataValuesDate[`${data.reference}${numDate + 2}${numFunction + 2}`] = ['',data.type,'hasta',data.functionData] 
                            
                            numFunction = numFunction + 2
                        }
                        
                        numDate = numDate + 2

                        return
                    }
                    if(data.type === "number"){
                        if(!data.functionData){

                            arrayDataValuesNumber[`${data.reference}${numNumber + 1}`] = ['',data.type,'maximo',data.functionData]
                            arrayDataValuesNumber[`${data.reference}${numNumber + 2}`] = ['',data.type,'minimo',data.functionData]
                        
                        }else{

                            arrayDataValuesNumber[`${data.reference}${numNumber + 1}${numFunction + 1}`] = ['',data.type,'maximo',data.functionData]
                            arrayDataValuesNumber[`${data.reference}${numNumber + 2}${numFunction + 2}`] = ['',data.type,'minimo',data.functionData]

                            numFunction = numFunction + 2
                        }
                        
                        numNumber = numNumber + 2

                        return
                    }

                    if(!data.functionData){
                        arrayDataValuesText[data.reference] = ['',data.type,data.functionData]    
                    }else{
                        arrayDataValuesText[`${data.reference}${numFunction + 1}`] = ['',data.type,data.functionData]
                        numFunction++
                    }
                })
                this.dataValues = [arrayDataValuesText,arrayDataValuesDate,arrayDataValuesNumber]
            }
        },
        watch: {
            boolVisible(newValue){
                if(!newValue){
                    this.arrayInputs = []
                }
            }
        }
    }
</script>

<style scoped>
    .container-input-search-filter{
        width: 100%;
        position: relative;
        height: auto;
        display: flex;
        flex-direction: row;
        left: 0px;
        margin-top:5px;
        background:rgb(233, 233, 233);
        border-radius:10px 10px 10px 10px;
        padding: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        opacity: 1;
        transition: all 0.75s ease;
    }
    .container-reset{
        width: auto;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        top: 100%;
        right: 0px;
    }
    .button{
        height: 35px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:14px ;
        z-index: 5;
        width: auto;
        font-weight: bold;
        padding: 2px 15px;
        margin-left: 0%;
        margin-top: 10px;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button:hover{
        background: transparent;
        border: 2px solid #297F87;
        transition: all 0.5s ease-in-out;
        color:#297F87 ;
    }
    .container-inputs{
        width: 250px;
    }
    .contenedor-filtros-seleccionados{
        width:calc(100% - 20px);
        margin-left:10px;
        margin-right:10px;
        background:rgb(227, 227, 227); 
        border-radius:10px; 
        height:140px; 
        overflow:hidden scroll; 
        padding-bottom:10px;
        margin-top:20px;
    }
    .cuadro-filtro-seleccionado{
        float:left;
        background: white;
        width: 140px;
        height:35px;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        color: black;
        font-family: Rubik;
        font-size:18px ;
        border-radius:10px ;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2), inset 0 0 5px white;
        margin-top:10px;
        margin-bottom:0px;
        margin-left:5%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>